const pricingData = [
    {
        icon: 'ri-edit-box-line',
        title: '7-days',
        price: 8.99,
        subID: 'price_1LpVTSLQOK7u9EqPCQ3QH6fC'
    },
    {
        icon: 'ri-medal-line ',
        title: '30-days',
        price: 19.99,
        subID: 'price_1LpVTSLQOK7u9EqPqTTinow0'
    },
    {
        icon: 'ri-stack-line',
        title: '90-days',
        price: 29.99,
        subID: 'price_1LpVTSLQOK7u9EqPVjJ2Dq4D'
    }
]

export { pricingData };
