<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";

import { pricingData } from "./data";

import {
  axiosApi
} from '@/services/axios';

/**
 * Pricing component
 */
export default {
  page: {
    title: `New Campaign`,
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader
  },
  created() {
    this.loading = true
    axiosApi.get(`/tracks/${this.$route.query.trackID}?fields=id name images`).then(response => {
      this.trackData = response
      this.trackData.img = response.images[0].url
      this.loading = false
    })
  },
  data() {
    return {
      title: `New Campaign • ${this.$route.query.trackName}`,
      items: [
        {
          text: "99subs",
          href: "/"
        },
        {
          text: "New Campaign",
          active: true
        }
      ],
      pricingData: pricingData,
      trackData: null,
    };
  },
  methods: {
    async checkout(priceData) {

      this.$gtag.query('event', 'begin_checkout', {
        "items": [
          {
            "id": priceData.subID,
            "name": priceData.title,
            "brand": `${this.trackData.name}|${this.trackData.id}`,
            "category": "Campaign",
            "list_position": 1,
            "quantity": 1,
            "price": priceData.price
          }
        ],
        "coupon": ""
      })

      const response = await axiosApi.get(`/stripe/checkout?subID=${priceData.subID}&trackID=${this.trackData.id}&trackName=${this.trackData.name}`).catch((err) => {
        console.error(err);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: 'Don\'t worry, we have been notified and are working on it.'
        })
      })

      window.location.href = response.redirect
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">

      <div class="product-detail">
        <div class="row text-center">
          <div class="col-md-12 col-xl-3">
            <div
              class="tab-content mb-3"
              id="v-pills-tabContent"
            >
              <div
                class="tab-pane fade show active"
                id="product-1"
                role="tabpanel"
              >
                <div class="product-img track-img">
                  <img
                    v-if="trackData && trackData.img"
                    id="expandedImg"
                    :src="trackData.img"
                    alt
                    class="img-fluid mx-auto d-block"
                  />
                </div>
              </div>
            </div>
            <h5 class="text-primary"><strong>{{ this.$route.query.trackName }}</strong></h5>
          </div>

          <div class="col-xl-3 col-sm-12" v-for="(price, index) in pricingData" :key="index">
            <div class="card pricing-box">
              <div class="card-body p-4">
                <div class="text-center">
                  <div class="mt-3">
                    <i :class="`${price.icon} text-primary h1`"></i>
                  </div>
                  <h5 class="mt-4">{{ price.title }}</h5>

                  <div class="font-size-14 mt-4 pt-2">
                    <ul class="list-unstyled plan-features">
                      <li>Free Live Support</li>
                      <li>Unlimited User</li>
                      <li>No Time Tracking</li>
                    </ul>
                  </div>

                  <div class="mt-5">
                    <h1 class="font-weight-bold mb-1">
                      <sup class="mr-1">
                        <small>$</small>
                      </sup>{{price.price}}
                    </h1>
                    <!-- <p class="text-muted">Per month</p> -->
                  </div>

                  <div class="mt-5 mb-3">
                    <b-button @click.prevent="checkout(price)" variant="primary"><strong>Get started</strong></b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- end row -->

    <!-- values-banner -->
    <!-- <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="media">
                  <div class="avatar-sm mr-3">
                    <span class="avatar-title bg-light rounded-circle text-primary font-size-24">
                      <i class="ri-checkbox-circle-line"></i>
                    </span>
                  </div>
                  <div class="media-body align-self-center overflow-hidden">
                    <h5>Free Shipping</h5>
                    <p class="text-muted mb-0">Sed ut perspiciatis unde</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="media mt-4 mt-md-0">
                  <div class="avatar-sm mr-3">
                    <span class="avatar-title bg-light rounded-circle text-primary font-size-24">
                      <i class="ri-exchange-line"></i>
                    </span>
                  </div>
                  <div class="media-body align-self-center overflow-hidden">
                    <h5>Easy Return</h5>
                    <p class="text-muted mb-0">Neque porro quisquam est</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="media mt-4 mt-md-0">
                  <div class="avatar-sm mr-3">
                    <span class="avatar-title bg-light rounded-circle text-primary font-size-24">
                      <i class="ri-money-dollar-circle-line"></i>
                    </span>
                  </div>
                  <div class="media-body align-self-center overflow-hidden">
                    <h5>Cash on Delivery</h5>
                    <p class="text-muted mb-0">Ut enim ad minima quis</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- end row -->
  </Layout>
</template>

<style scoped>
.product-detail .track-img {
  border: 1px solid #ced4da;
}
</style>
